import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29169fdf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "memberList",
  ref: "dialog"
}
const _hoisted_2 = {
  key: 0,
  class: "memberList-title"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "memberList-box" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showAtList)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["message-input-at", [_ctx.isH5 && 'message-input-at-h5']]),
        ref: "MessageInputAt"
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('TUIChat.选择提醒的人')), 1),
                _createElementVNode("i", {
                  class: "icon icon-close close",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeAt && _ctx.closeAt(...args)))
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showMemberList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["memberList-box-body", [index === _ctx.selectedIndex && 'selected']]),
                key: index,
                onClick: ($event: any) => (_ctx.selectItem(index)),
                ref_for: true,
                ref: "memberListItems"
              }, [
                _createElementVNode("img", {
                  src: (item as any)?.avatar || 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                }, null, 8, _hoisted_6),
                _createElementVNode("span", null, _toDisplayString((item as any)?.nick ? (item as any)?.nick : (item as any)?.userID), 1)
              ], 10, _hoisted_5))
            }), 128))
          ])
        ], 512)
      ], 2))
    : _createCommentVNode("", true)
}
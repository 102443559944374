
import TUIAegis from "../../../../../utils/TUIAegis";
import { Search } from "@element-plus/icons-vue";
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  onMounted,
} from "vue";
import LiteItem from "../list-item";
import ImSearch from "../im-search";
import LiteItem7 from "../list-item-s";
import eventBus from "../../../../utils/eventBus";
import { getExpireList } from "@/api";
const params = new URLSearchParams(window.location.search);
const token: any = params.get("token");
const expList: any = ref([]);
const TUIConversationList: any = defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    currentID: {
      type: String,
      default: () => "",
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    displayOnlineStatus: {
      type: Boolean,
      default: () => false,
    },
    userStatusList: {
      type: Map,
      default: () => new Map(),
    },
  },
  components: { LiteItem, LiteItem7, ImSearch },
  setup(props: any, ctx: any) {
    // const oldList:any = props.data
    const TUIServer: any = TUIConversationList?.TUIServer;
    const obj = reactive({
      data: {},
      currentID: "",
      toggleID: "",
      displayOnlineStatus: false,
      userStatusList: new Map(),
      types: TUIServer.TUICore.TIM.TYPES,
    });
    const list: any = ref();
    const searchInp: any = ref("");
    watchEffect(() => {
      obj.data = props.data;
      obj.currentID = props.currentID;
      obj.displayOnlineStatus = props.displayOnlineStatus;
      obj.userStatusList = props.userStatusList;
    });

    watch(
      () => props.userStatusList,
      () => {
        obj.userStatusList = props.userStatusList;
      },
      { deep: true }
    );

    onMounted(() => {
      getExpireListFunc();
    });

    const handleListItem = (item: any) => {
      eventBus.emit("getShopInfo", item);
      ctx.emit("handleItem", item);
    };
    const handleListItemS = (item: any) => {
      console.log(item, "ccc");
      expList.value.splice(item, 1);
      // eventBus.emit("getShopInfo", item);
      // ctx.emit("handleItem", item);
    };

    const handleItem = (params: any) => {
      const { name, conversation } = params;
      TUIAegis.getInstance().reportEvent({
        name: "conversationOptions",
        ext1: "conversationOptions",
      });
      switch (name) {
        case "delete":
          handleDeleteConversation(conversation);
          break;
        case "ispinned":
          handlePinConversation(name, conversation);
          break;
        case "dispinned":
          handlePinConversation(name, conversation);
          break;
        case "mute":
          handleMuteConversation(name, conversation);
          break;
        case "notMute":
          handleMuteConversation(name, conversation);
          break;
      }
    };

    const handleDeleteConversation = (conversation: any) => {
      TUIServer.deleteConversation(conversation.conversationID).then(
        (imResponse: any) => {
          TUIAegis.getInstance().reportEvent({
            name: "conversationOptions",
            ext1: "conversationDelete",
          });
          const { conversationID } = imResponse.data;
          const { conversation } = TUIServer.TUICore.getStore().TUIChat;
          // 删除会话，判断当前删除的会话是否为打开的会话
          // 若为打开的会话，通知 TUIChat 关闭当前会话
          // Delete session: judge whether the currently deleted session is an open session
          // If it is an open session, notify tuichat to close the current session
          if (conversation.conversationID === conversationID) {
            TUIServer.TUICore.getStore().TUIChat.conversation = {
              conversationID: "",
            };
          }
        }
      );
    };

    const handlePinConversation = (type: string, conversation: any) => {
      const options: any = {
        conversationID: conversation.conversationID,
        isPinned: true,
      };

      if (type === "dispinned") {
        options.isPinned = false;
      }
      console.log("dispinned", options);
      TUIServer.pinConversation(options);
    };

    const handleMuteConversation = (type: string, conversation: any) => {
      const options: any = {
        messageRemindType: TUIServer.TUICore.TIM.TYPES.MSG_REMIND_ACPT_NOT_NOTE,
      };

      if (conversation.type === TUIServer.TUICore.TIM.TYPES.CONV_C2C) {
        options.userIDList = [conversation.userProfile.userID];
      } else if (conversation.type === TUIServer.TUICore.TIM.TYPES.CONV_GROUP) {
        options.groupID = conversation.groupProfile.groupID;
      }

      if (type === "notMute") {
        options.messageRemindType =
          TUIServer.TUICore.TIM.TYPES.MSG_REMIND_ACPT_AND_NOTE;
      }

      TUIServer.muteConversation(options);
    };

    const handleToggleListItem = (conversationID: string) => {
      obj.toggleID = conversationID;
    };

    const scrollChange = () => {
      obj.toggleID && (obj.toggleID = "");
    };

    const getExpireListFunc = () => {
      let selfId = localStorage.getItem("selfId");
      // setTimeout(()=>{
      //   console.log(props.data.list,'xxxx');
      // },1000)
      let setIntervalMs = 0
      let getListItv = setInterval(() => {
        setIntervalMs++
        if (props.data.list.length||setIntervalMs>3) {
          clearInterval(getListItv);
          // console.log(props.data.list[0], "数据拿到了");
          getExpireList({ mId: selfId }).then((res: any) => {
            let explists = res.data;
            let txList = props.data.list;
            // expList.value = res.data;
            expList.value = explists.filter(function (itemB: any) {
              // 检查数组 a 中是否存在相同 conversationID 的对象
              return !txList.some(function (itemA: any) {
                return itemA.conversationID === itemB.conversationID;
              });
            });
            // console.log(filteredB);
          });
        }
      }, 1000);
    };
    const searchCt = () => {
      ctx.emit("upDateList", searchInp.value);

      if (!searchInp.value) {
        getExpireListFunc();
        return;
      }

      let newList: any = [];
      // 进一步放宽参数 x 的类型
      expList.value.filter(
        (x: { to_id?: { Tag_Profile_IM_Nick?: string } }) => {
          if (
            x?.to_id?.Tag_Profile_IM_Nick &&
            x.to_id.Tag_Profile_IM_Nick.toUpperCase().indexOf(
              searchInp.value.toUpperCase()
            ) > -1
          ) {
            newList.push(x);
          }
        }
      );
      expList.value = newList;
    };

    return {
      ...toRefs(obj),
      handleListItem,
      handleItem,
      handleToggleListItem,
      list,
      expList,
      searchCt,
      handleListItemS,
      scrollChange,
      searchInp,
      Search,
    };
  },
});
export default TUIConversationList;

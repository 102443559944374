
import { defineComponent, reactive, watchEffect, toRefs, ref } from 'vue';
import Transfer from '../../../../components/transfer/index.vue';
import { handleErrorPrompts } from '../../../utils';
import { onClickOutside } from '@vueuse/core';

const Forward = defineComponent({
  components: {
    Transfer,
  },
  name: '转发',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    message: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      list: [],
      show: false,
      to: -1,
    });

    const dialog: any = ref();

    watchEffect(() => {
      data.list = props.list;
      data.show = props.show;
    });

    const toggleShow = () => {
      data.show = !data.show;
      if (!data.show) {
        ctx.emit('update:show', data.show);
        data.to = -1;
      }
    };

    onClickOutside(dialog, () => {
      data.show = false;
      ctx.emit('update:show', data.show);
      data.to = -1;
    });

    const handleForWordMessage = async (list: any) => {
      list.map(async (item: any) => {
        try {
          await Forward.TUIServer.forwardMessage(props.message, item);
        } catch (error) {
          console.log(6666666);
          handleErrorPrompts(error, props);
        }
      });
      toggleShow();
    };

    return {
      ...toRefs(data),
      toggleShow,
      handleForWordMessage,
      dialog,
    };
  },
});
export default Forward;

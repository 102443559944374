
import { defineComponent, reactive, toRefs, computed, watch } from 'vue';
import TUIConversationList from './components/list';
import { caculateTimeago, isArrayEqual } from '../utils';
import { handleAvatar, handleName, handleShowLastMessage, handleAt } from '../TUIChat/utils/utils';

const TUIConversation = defineComponent({
  name: 'TUIConversation',

  components: {
    TUIConversationList,
  },
  props: {
    displayOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversation?.TUIServer;
    const data = reactive({
      currentConversationID: '',
      conversationData: {
        list: [],
        handleItemAvator: (item: any) => handleAvatar(item),
        handleItemName: (item: any) => handleName(item),
        handleShowAt: (item: any) => handleAt(item),
        handleShowMessage: (item: any) => handleShowLastMessage(item),
        handleItemTime: (time: number) => {
          if (time > 0) {
            return caculateTimeago(time * 1000);
          }
          return '';
        },
      },
      userIDList: new Set(),
      netWork: '',
      env: TUIServer.TUICore.TUIEnv,
      displayOnlineStatus: false,
      userStatusList: TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList,
    });
    TUIServer.bind(data);

    TUIConversationList.TUIServer = TUIServer;

    watch(
      () => data.currentConversationID,
      (newVal: any) => {
        ctx.emit('current', newVal);
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
        data.userStatusList = TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList;
      },
      { immediate: true }
    );

    watch(
      () => [...data.userIDList],
      async (newVal: any, oldVal: any) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
      },
      {
        deep: true,
      }
    );

    const isNetwork = computed(() => {
      const disconnected = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_DISCONNECTED;
      const connecting = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_CONNECTING;
      return disconnected || connecting;
    });

    const handleCurrentConversation = (value: any) => {
      TUIServer.handleCurrentConversation(value);
    };
    let oldList:any = []
    let setIntervalMs = 0
    let getListItv =  setInterval(()=>{
      setIntervalMs++
      if(data.conversationData.list.length||setIntervalMs>3){
        clearInterval(getListItv);
        oldList = JSON.parse(JSON.stringify(data.conversationData));
        // console.log(oldList,'数据拿到了');
      }
    },1000)
    // setTimeout(()=>{
    //   oldList = JSON.parse(JSON.stringify(data.conversationData));
    // },500)
    const upDateList =(value:any)=>{
      if(!value){
        data.conversationData.list = oldList.list
        return;
      }
     let newList2:any = [];
      oldList.list.filter(
        (x: { userProfile?: { nick?: string } }) => {
          if (
            x?.userProfile?.nick &&
            (x.userProfile.nick.toUpperCase()).indexOf(value.toUpperCase()) > -1
          ) {
            newList2.push(x);
          }
        }
      );
      data.conversationData.list = newList2
    };

    return {
      ...toRefs(data),
      handleCurrentConversation,
      upDateList,
      isNetwork,
    };
  },
});
export default TUIConversation;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3266b21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "face-list collapse" }
const _hoisted_2 = { class: "face-list-collapse" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "face-list face-list-expand" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "emoji-content"
}
const _hoisted_9 = {
  class: "emoji-react",
  ref: "container"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "emoji-react-item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type === 'dropdown')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dialog-emoji", _ctx.env?.isH5?'dialog-emoji-h5':''])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emojiCollapseList, (childrenItem, childrenIndex) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "face-list-item",
                  key: childrenIndex,
                  onClick: _withModifiers(($event: any) => (_ctx.select(childrenItem)), ["stop"])
                }, [
                  _createElementVNode("img", {
                    src: _ctx.emojiUrl + _ctx.emojiMap[childrenItem]
                  }, null, 8, _hoisted_4)
                ], 8, _hoisted_3))
              }), 128))
            ]),
            _createElementVNode("div", {
              class: "face-list-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isCollapse = !_ctx.isCollapse), ["stop"]))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon", [_ctx.isCollapse ? 'icon-expand' : 'icon-collapse']])
              }, null, 2)
            ])
          ]),
          _withDirectives(_createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emojiExpandList, (childrenItem, childrenIndex) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "face-list-item",
                key: childrenIndex,
                onClick: _withModifiers(($event: any) => (_ctx.select(childrenItem)), ["stop"])
              }, [
                _createElementVNode("img", {
                  src: _ctx.emojiUrl + _ctx.emojiMap[childrenItem]
                }, null, 8, _hoisted_7)
              ], 8, _hoisted_6))
            }), 128))
          ], 512), [
            [_vShow, !_ctx.isCollapse]
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.type === 'content')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("ul", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handleEmojiReact(_ctx.message), (val, key) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                class: "emoji-react-item",
                key: key,
                onClick: _withModifiers(($event: any) => (_ctx.select(key)), ["stop"])
              }, [
                _createElementVNode("img", {
                  src: _ctx.emojiUrl + _ctx.emojiMap[key]
                }, null, 8, _hoisted_11),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.handleEmojiReactItem(val)), 1)
                ])
              ], 8, _hoisted_10)), [
                [_vShow, val && val?.length]
              ])
            }), 128))
          ], 512)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
import { post,get } from '../utils/request'




export function getDemandDetail<T>(data:any) {
  return get<T>({
    url: '/demand/detail',
    data,
  })
}

export function getExpireList<T>(data:any) {
  return post<T>({
    url: 'https://www.icspec.com/api/ictrade/ImApi/getTokenList',
    data,
  })
}


export function getHistoryMessage<T>(data:any) {
  return post<T>({
    url: 'https://www.icspec.com/api/ictrade/ImApi/getChatList',
    data,
  })
}


export function imSearch<T>(data:any) {
  return post<T>({
    url: 'https://www.icspec.com/api/ictrade/ImApi/keywordSearch',
    data,
  })
}
export function contentSearch<T>(data:any) {
  return post<T>({
    url: 'https://www.icspec.com/api/ictrade//ImApi/keywordSearch_2.2',
    data,
  })
}


// export function getprovince<T>(data:any) {
//   return get<T>({
//     url: '/ImApi/getprovince',
//     data,
//   })
// }



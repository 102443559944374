import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore } from './TUIKit';
// import TUICallKit
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import 'element-plus/dist/index.css'
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import axios from 'axios'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const SDKAppID = 1400806452; // Your SDKAppID
// const userID = 'lihenghong'; // User ID
const params = new URLSearchParams(window.location.search)

const userId = params.get('toUserId')
const token = params.get('token')
const adminToken: any = params.get('adminToken')
const mallMerchantUserId = params.get('mallMerchantUserId')
// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
if (token || adminToken) {
  localStorage.setItem("token", token || adminToken);
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.interceptors.request.use((config) => {
    config.headers["token"] = token
    if (adminToken) {
      config.headers["Merchant-Token"] = adminToken;
      config.headers['Merchant-User-Id'] = mallMerchantUserId || "";
    }
    return config;
  });


}

if (adminToken) {
  // https://www.icspec.com/api/
  axios.post('https://www.icspec.com/api/im/session/init/merchant', {
    // axios.get('https://www.icspec.com/api/ictrade/im/index', {
    mallMerchantUserId,
    token: adminToken
  })
    .then(response => {
      TUIKit.login({
        userID: response.data.data.loginAccountId,
        userSig: response.data.data.sign// The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
      });
      localStorage.setItem("serverId", response.data.data.accountId);
      // const shopdata: any = JSON.parse(localStorage.getItem("shopIdData") || "{}") || {};
      // shopdata[response.data.data.service] = userId;
      localStorage.setItem("selfId", response.data.data.loginAccountId)
      TUIKit.use(TUIComponents);
      TUIKit.use(TUICallKit);
      const app = createApp(App)
      for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
      }
      app.use(TUIKit).use(ElementPlus, {
        locale: zhCn,
      }).mount('#app');
    })
    .catch(error => {
      console.error(error)
    })
} else {
  // https://www.icspec.com/api/
  axios.post('https://www.icspec.com/api/im/session/init', {
    // axios.get('https://www.icspec.com/api/ictrade/im/index', {
    userId,
    token
  })
    .then(response => {
      TUIKit.login({
        userID: response.data.data.loginAccountId,
        userSig: response.data.data.sign// The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
      });
      localStorage.setItem("serverId", response.data.data.accountId);
      // const shopdata: any = JSON.parse(localStorage.getItem("shopIdData") || "{}") || {};
      // shopdata[response.data.data.service] = userId;
      localStorage.setItem("selfId", response.data.data.loginAccountId)
      TUIKit.use(TUIComponents);
      TUIKit.use(TUICallKit);
      const app = createApp(App)
      for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
      }
      app.use(TUIKit).use(ElementPlus, {
        locale: zhCn,
      }).mount('#app');
    })
    .catch(error => {
      console.error(error)
    })
}



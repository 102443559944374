import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec7124a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "message-image",
  ref: "skeleton"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "message-img",
      src: _ctx.data.url,
      style: _normalizeStyle(
        _ctx.isH5
          ? {
              maxWidth: _ctx.data.width ? _ctx.data.width + 'px' : 'calc(100vw - 180px)',
              maxHeight: _ctx.data.height ? _ctx.data.height + 'px' : 'calc(100vw - 180px)',
            }
          : {}
      )
    }, null, 12, _hoisted_2)
  ], 512))
}